<template>
  <div class="event-registration-page">
    <div class="page-header">{{ $t("eventRegistration.statement") }}</div>
    <div class="main-content">
      <div class="event-register-form">
        <ui-form type="1" item-margin-bottom="10">
          <div class="center">
            <ui-form-field>
              <ui-checkbox
                v-model="imVaccinated"
                input-id="imVaccinated"
              ></ui-checkbox>
              <label for="imVaccinated">{{
                $t("eventRegistration.vaccinated")
              }}</label>
            </ui-form-field>
          </div>
          <div class="center">
            <ui-form-field>
              <ui-checkbox v-model="hadCovid" input-id="hadCovid"></ui-checkbox>
              <label for="hadCovid">{{
                $t("eventRegistration.recovered")
              }}</label>
            </ui-form-field>
          </div>
          <div class="center">
            <ui-form-field>
              <ui-checkbox
                v-model="validPcrTest"
                input-id="validPcrTest"
              ></ui-checkbox>
              <label for="validPcrTest">{{
                $t("eventRegistration.tested")
              }}</label>
            </ui-form-field>
          </div>
          <div class="center">
            <ui-form-field class="text-field required">
              <ui-textfield
                outlined
                v-model="name"
                :attrs="{ name: 'name' }"
                required
                >{{ $t("name") }}</ui-textfield
              >
            </ui-form-field>
          </div>
          <div class="center">
            <ui-form-field class="has-children-switch-field">
              <ui-switch
                v-model="hasChildren"
                input-id="hasChildren"
                v-on:change="hasChildrenSwitchChanged()"
                :true-value="true"
                :false-value="false"
                >{{ hasChildren }}</ui-switch
              >
              <label for="hasChildren" class="switch-label">{{
                $t("eventRegistration.participatingWithKids")
              }}</label>
            </ui-form-field>
          </div>
          <div class="center">
            <ui-form-field v-if="hasChildren" class="text-field required">
              <ui-textfield
                v-model="numberOfChildren"
                v-on:change="numberOfChildrenChanged()"
                outlined
                :attrs="{ name: 'numberOfChildren' }"
                input-type="number"
                required
                >{{ $t("eventRegistration.childrenNumber") }}</ui-textfield
              >
            </ui-form-field>
          </div>
          <div
            v-if="hasChildren && numberOfChildren > 0"
            class="center childrens-list"
          >
            <ui-form-field
              v-for="index in Number(numberOfChildren)"
              :key="index"
              class="text-field required"
            >
              <ui-textfield
                outlined
                :attrs="{ name: 'numberOfChildren' }"
                required
                v-model="childrenList[index - 1]"
                >{{ index }}.
                {{ $t("eventRegistration.childName") }}</ui-textfield
              >
            </ui-form-field>
          </div>
          <div class="center">
            <ui-alert
              class="fixed-width"
              state="error"
              v-if="errorMessage !== ''"
              >{{ errorMessage }}</ui-alert
            >
          </div>
          <div class="terms-description">
            <p>
              {{ $t("eventRegistration.statementText") }}
            </p>
          </div>
          <div class="center">
            <ui-button
              raised
              class="accept-button"
              @click="statementAccepted()"
              :disabled="statementSaveInProgress || formInvalid"
              >{{ $t("accept") }}</ui-button
            >
          </div>
        </ui-form>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import Constants from "../core/Constants";
import toastService from "../services/toast.service";
import userService from "../services/user.service";

@Options({})
export default class EventRegistration extends Vue {
  public statementSaveInProgress = false;
  public errorMessage = "";
  public imVaccinated = false;
  public hadCovid = false;
  public validPcrTest = false;
  public hasChildren = false;
  public name = null;
  public numberOfChildren = 0;
  public childrenList: string[] = [];

  mounted(): void {
    const user = userService.localUserDetails;
    this.name = user?.name || null;
    this.imVaccinated = user?.vaccinated || false;
    this.hadCovid = user?.recovered || false;
    this.validPcrTest = user?.tested || false;
    this.numberOfChildren = user?.children || null;
    if (this.numberOfChildren > 0) {
      this.hasChildren = true;
      this.numberOfChildrenChanged();
      for (let i = 0; i < this.childrenList.length; i++) {
        this.childrenList[i] = user[`child${i + 1}`] || "";
      }
    }
  }

  public get formInvalid(): boolean {
    return (
      !this.name ||
      this.name === "" ||
      !(this.imVaccinated || this.hadCovid || this.validPcrTest)
    );
  }

  public async statementAccepted(): Promise<void> {
    try {
      this.errorMessage = "";
      if (this.formInvalid) {
        return;
      }
      this.statementSaveInProgress = true;
      const userStatementUpdate = await userService.updateUserStatement(
        this.name,
        this.imVaccinated,
        this.hadCovid,
        this.validPcrTest,
        this.numberOfChildren,
        this.childrenList
      );
      if (userStatementUpdate?.data?.id) {
        const userDetails = await userService.getUserDetails();
        localStorage.setItem(
          Constants.USER_DATA_STORAGE_KEY,
          JSON.stringify(userDetails?.data)
        );
        this.$router.push("/programs");
      }
    } catch (error) {
      this.errorMessage = this.$t("errorMsgs.statementError");
      console.warn(error.response.data);
    }
    this.statementSaveInProgress = false;
  }

  public hasChildrenSwitchChanged(): void {
    if (this.hasChildren === false) {
      this.numberOfChildren = 0;
      this.childrenList = [];
    } else {
      this.numberOfChildren = 1;
      this.numberOfChildrenChanged();
    }
  }

  public numberOfChildrenChanged(): void {
    if (this.numberOfChildren > 6) {
      this.numberOfChildren = 6;
      toastService.showToast(this.$t("errorMsgs.max6Kids"));
    }
    const currentLength = this.childrenList.length;
    const newLength = this.numberOfChildren;
    if (newLength < currentLength) {
      this.childrenList.splice(newLength);
    } else {
      const newArray: string[] = [];
      for (let i = 0; i < newLength; i++) {
        if (this.childrenList[i]) {
          newArray.push(this.childrenList[i]);
        } else {
          newArray.push("");
        }
      }
      this.childrenList = newArray;
    }
  }
}
</script>

<style lang="scss" scoped>
div.center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.fixed-width {
  width: 418px;
}
div.event-registration-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  div.terms-description {
    white-space: pre-line;
    padding: 1rem;
    p {
      margin: 2rem 0;
      max-width: 900px;
    }
  }
  div.main-content {
    min-width: 350px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1rem;
    box-sizing: border-box;
    div.event-register-form {
      .text-field {
        margin-top: 1rem;
      }
      .mdc-form-field,
      .mdc-text-field {
        min-width: 350px;
        max-width: 450px;
        width: 100%;
      }
      .has-children-switch-field {
        margin-top: 20px;
        .switch-label {
          margin-left: 10px;
        }
      }
      div.childrens-list {
        display: flex;
        flex-direction: column;
      }
      .accept-button {
        min-width: 350px;
        max-width: 450px;
        width: 100%;
        margin-top: 20px;
      }
    }
  }
}
</style>
