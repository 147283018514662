<template>
  <div class="login-page centered-v-page account-page">
    <div class="main-section">
      <div class="logo-wrapper flex-h-center">
        <img
          src="../assets/img/logo.png"
          class="logo"
          @click="$router.push('/home')"
        />
      </div>
      <div class="page-title flex-h-center">
        {{ $t("createAccount.createAccount") }}
      </div>
      <div class="main-content">
        <ui-form type="1" item-margin-bottom="20" class="register-form">
          <ui-form-field>
            <ui-textfield
              outlined
              :attrs="{ name: 'email' }"
              v-model="email"
              required
              @keyup.enter="createAccount()"
              >{{ $t("email") }}</ui-textfield
            >
          </ui-form-field>
          <ui-form-field class="form-field-vertical">
            <ui-textfield
              outlined
              :input-type="'password'"
              :attrs="{ name: 'password' }"
              v-model="password"
              helper-text-id="pw-validation-msg"
              required
              @keyup.enter="createAccount()"
              >{{ $t("password") }}</ui-textfield
            >
            <ui-textfield-helper id="pw-validation-msg" visible validMsg
              >{{ $t("min8CharactersHint") }}
            </ui-textfield-helper>
          </ui-form-field>
          <ui-form-field>
            <ui-textfield
              outlined
              :input-type="'password'"
              :attrs="{ name: 'passwordRepeat' }"
              v-model="confirmPassword"
              required
              @keyup.enter="createAccount()"
              >{{ $t("confirmPassword") }}</ui-textfield
            >
          </ui-form-field>
          <ui-form-field>
            <ui-checkbox v-model="gdpr" input-id="gdpr"></ui-checkbox>
            <label for="gdpr"
              ><span v-html="$t('createAccount.gdpr')"></span
            ></label>
          </ui-form-field>
          <ui-alert state="error" v-if="errorMessage !== ''">
            <span v-html="errorMessage"></span>
          </ui-alert>
          <ui-button
            raised
            @click="createAccount()"
            :disabled="accountCreationInProgress || formInvalid"
          >
            <span v-if="!accountCreationInProgress">{{
              $t("createAccount.createAccountBtn")
            }}</span>
            <ui-spinner active v-if="accountCreationInProgress"></ui-spinner>
          </ui-button>
          <ui-button class="login-button" @click="navigateToLogin()">{{
            $t("haveAccountLogin")
          }}</ui-button>
        </ui-form>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import authService from "../services/auth.service";
import toastService from "../services/toast.service";
import { Options, Vue } from "vue-class-component";

@Options({
  components: {},
})
export default class AccountCreate extends Vue {
  public email = null;
  public password = null;
  public confirmPassword = null;
  public accountCreationInProgress = false;
  public errorMessage = "";
  public gdpr = false;

  public get formInvalid(): boolean {
    return (
      !this.email ||
      this.email === "" ||
      !this.password ||
      this.password === "" ||
      !this.confirmPassword ||
      this.confirmPassword === "" ||
      !this.gdpr
    );
  }

  public createAccount(): void {
    this.accountCreationInProgress = true;
		if (this.formInvalid)
		{
			this.errorMessage = "A regisztrációs űrlap hiányos!";
			this.accountCreationInProgress = false;
			return;
		}
    this.errorMessage = "";
    authService
      .register({
        email: this.email,
        password: this.password,
        confirmPassword: this.confirmPassword,
		language: this.$i18n.locale
      })
      .then((success) => {
        if (success?.data?.success) {
          toastService.showToast(
            "A fiók létrehozása sikeres. Kérjük aktiválja fiókját az email-re küldött link segitségével."
          );
          this.$router.push("/login");
        }
      })
      .catch((error) => {
        if (error.response) {
          this.errorMessage = "Hiba történt a fiók létrehozása közben!<br>";
          const errorObj = error.response?.data?.error;
          for (const errorKey in errorObj) {
            for (const errorMsg of errorObj[errorKey]) {
              this.errorMessage += `&bull; <b>${errorMsg}</b><br>`;
            }
          }
        }
      })
      .then(() => {
        this.accountCreationInProgress = false;
      });
  }

  public navigateToLogin(): void {
    this.$router.push("login");
  }
}
</script>

<style lang="scss" scoped>
div.login-page {
  div.main-section {
    padding: 1rem;
    div.logo-wrapper {
      padding: 1rem;
      img.logo {
        width: 200px;
        height: auto;
      }
    }
    div.main-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 20px;

      .register-form {
        min-width: 300px;
        max-width: 350px;
        width: 100%;
        .form-field-vertical {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
        }
        .mdc-form-field,
        .mdc-text-field {
          width: 100%;
        }
        .login-button {
          margin-top: 10px;
        }
      }

      button {
        min-width: 200px;
        max-width: 350px;
        width: 100%;
      }
    }
  }
}

@media (min-width: 641px) {
  div.home {
    padding: 30vh;
  }
}
</style>
