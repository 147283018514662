<template>
  <div class="home-page">
    <div class="actions-section">
      <div class="logo-wrapper">
        <img src="../assets/img/logo.png" class="logo" />
      </div>
      <div class="main-content">
        <ui-button raised @click="navigateToLogin()">{{
          $t("login")
        }}</ui-button>
        <ui-button raised @click="navigateToRegister()">{{
          $t("createAccount.createAccount")
        }}</ui-button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";

@Options({})
export default class Home extends Vue {
  public navigateToLogin(): void {
    this.$router.push("/login");
  }
  public navigateToRegister(): void {
    this.$router.push("/create-account");
  }
}
</script>

<style lang="scss" scoped>
div.home-page {
  min-height: $pageMinHeight;
  background: $basePurple;
  div.actions-section {
    padding-top: 10vh;
    img.logo {
      width: 200px;
      height: auto;
    }
    div.logo-wrapper {
      padding: 1rem;
      display: flex;
      justify-content: center;
    }
    div.main-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 20px;

      button {
        background: $baseYellow;
        color: black;
        min-width: 200px;
        max-width: 350px;
        width: 100%;
      }
    }
  }
}

@media (min-width: 641px) {
  div.home-page {
    div.actions-section {
      padding-top: 30vh;
    }
  }
}
</style>
