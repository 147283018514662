
import { Options, Vue } from "vue-class-component";
import Constants from "../core/Constants";
import toastService from "../services/toast.service";
import userService from "../services/user.service";

@Options({})
export default class EventRegistration extends Vue {
  public statementSaveInProgress = false;
  public errorMessage = "";
  public imVaccinated = false;
  public hadCovid = false;
  public validPcrTest = false;
  public hasChildren = false;
  public name = null;
  public numberOfChildren = 0;
  public childrenList: string[] = [];

  mounted(): void {
    const user = userService.localUserDetails;
    this.name = user?.name || null;
    this.imVaccinated = user?.vaccinated || false;
    this.hadCovid = user?.recovered || false;
    this.validPcrTest = user?.tested || false;
    this.numberOfChildren = user?.children || null;
    if (this.numberOfChildren > 0) {
      this.hasChildren = true;
      this.numberOfChildrenChanged();
      for (let i = 0; i < this.childrenList.length; i++) {
        this.childrenList[i] = user[`child${i + 1}`] || "";
      }
    }
  }

  public get formInvalid(): boolean {
    return (
      !this.name ||
      this.name === "" ||
      !(this.imVaccinated || this.hadCovid || this.validPcrTest)
    );
  }

  public async statementAccepted(): Promise<void> {
    try {
      this.errorMessage = "";
      if (this.formInvalid) {
        return;
      }
      this.statementSaveInProgress = true;
      const userStatementUpdate = await userService.updateUserStatement(
        this.name,
        this.imVaccinated,
        this.hadCovid,
        this.validPcrTest,
        this.numberOfChildren,
        this.childrenList
      );
      if (userStatementUpdate?.data?.id) {
        const userDetails = await userService.getUserDetails();
        localStorage.setItem(
          Constants.USER_DATA_STORAGE_KEY,
          JSON.stringify(userDetails?.data)
        );
        this.$router.push("/programs");
      }
    } catch (error) {
      this.errorMessage = this.$t("errorMsgs.statementError");
      console.warn(error.response.data);
    }
    this.statementSaveInProgress = false;
  }

  public hasChildrenSwitchChanged(): void {
    if (this.hasChildren === false) {
      this.numberOfChildren = 0;
      this.childrenList = [];
    } else {
      this.numberOfChildren = 1;
      this.numberOfChildrenChanged();
    }
  }

  public numberOfChildrenChanged(): void {
    if (this.numberOfChildren > 6) {
      this.numberOfChildren = 6;
      toastService.showToast(this.$t("errorMsgs.max6Kids"));
    }
    const currentLength = this.childrenList.length;
    const newLength = this.numberOfChildren;
    if (newLength < currentLength) {
      this.childrenList.splice(newLength);
    } else {
      const newArray: string[] = [];
      for (let i = 0; i < newLength; i++) {
        if (this.childrenList[i]) {
          newArray.push(this.childrenList[i]);
        } else {
          newArray.push("");
        }
      }
      this.childrenList = newArray;
    }
  }
}
