<template>
	<div class="event-registration-page">
		<div class="page-header">{{$t("faq.title")}}</div>
		<div class="terms-description">
			<p v-html="$t('faq.content')"></p>
		</div>
	</div>
</template>

<style lang="scss" scoped>
div.event-registration-page {
	display: flex;
	flex-direction: column;
	align-items: center;

	div.terms-description {
		white-space: pre-line;
		padding: 1rem;
		p {
			margin: 2rem 0;
			max-width: 900px;
		}
	}
}
</style>
