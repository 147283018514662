
import { Options, Vue } from "vue-class-component";
import userService from "../services/user.service";
import toastService from "../services/toast.service";

@Options({})
export default class ForgotPassword extends Vue {
  public email = null;
  public emailSendInProgress = false;

  public async sendEmail(): Promise<void> {
    try {
      this.emailSendInProgress = true;
      await userService.resetPassword(this.email);
      toastService.showToast(
        this.$t("successMsgs.passwordResetEmailSent"),
        "success"
      );
      this.$router.push("login");
    } catch (error) {
      toastService.showToast(
        this.$t("errorMsgs.somethingWentWrongWhenSendingEmail"),
        "danger" 
      );
    }
    this.emailSendInProgress = false;
  }

  public navigateToLogin(): void {
    this.$router.push("login");
  }
}
