<template>
  <div class="password-reset-page centered-v-page account-page">
    <div class="main-section">
      <div class="logo-wrapper flex-h-center">
        <img
          src="../assets/img/logo.png"
          class="logo"
          @click="$router.push('/home')"
        />
      </div>
      <div class="page-title flex-h-center">
        {{ $t("passwordReset.passwordReset") }}
      </div>
      <div class="page-description flex-h-center">
        <span>{{ $t("passwordReset.hintText") }}</span>
      </div>
      <div class="main-content">
        <ui-form type="1" item-margin-bottom="20" class="password-reset-form">
          <ui-form-field class="form-field-vertical">
            <ui-textfield
              outlined
              :input-type="'password'"
              :attrs="{ name: 'password' }"
              helper-text-id="pw-help-text"
              minlength="8"
              v-model="password"
            >
              {{ $t("password") }}
            </ui-textfield>
            <ui-textfield-helper id="pw-help-text" visible>{{
              $t("min8CharactersHint")
            }}</ui-textfield-helper>
          </ui-form-field>
          <ui-form-field class="form-field-vertical">
            <ui-textfield
              outlined
              :input-type="'password'"
              :attrs="{ name: 'password' }"
              helper-text-id="pw-confirm-unmatch-error"
              v-model="confirmPassword"
            >
              {{ $t("confirmPassword") }}
            </ui-textfield>
            <ui-textfield-helper
              v-if="password && confirmPassword && password != confirmPassword"
              validMsg
              visible
              id="pw-confirm-unmatch-error"
              >{{ $t("passowordMatchError") }}</ui-textfield-helper
            >
          </ui-form-field>
          <ui-alert state="error" v-if="errorMessage !== ''">
            <span v-html="errorMessage"></span>
          </ui-alert>
          <ui-button
            raised
            @click="changePassword()"
            :disabled="
              passwordResetInProgress || !password || password?.length < 8
            "
          >
            <span v-if="!passwordResetInProgress">{{ $t("save") }}</span>
            <ui-spinner active v-if="passwordResetInProgress"></ui-spinner>
          </ui-button>
          <ui-button class="login-button" @click="navigateToLogin()">{{
            $t("changedMindLogin")
          }}</ui-button>
        </ui-form>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import userService from "../services/user.service";
import toastService from "../services/toast.service";

@Options({
  components: {},
})
export default class PasswordReset extends Vue {
  public userId: any = null;
  public hash: any = null;
  public password: any = null;
  public confirmPassword: any = null;
  public passwordResetInProgress: any = false;
  public errorMessage: any = "";

  async mounted(): Promise<void> {
    const routeParams = this.$route?.params;
    if (routeParams?.userId && routeParams?.hash) {
      this.userId = routeParams.userId;
      this.hash = routeParams.hash;
    } else {
      toastService.showToast(
        this.$t("passwordReset.missingParametersError"),
        "danger"
      );
      this.$router.push("/login");
    }
  }

  public async changePassword(): Promise<void> {
    if (this.password.length < 8) {
      toastService.showToast(
        this.$t("passwordReset.passwordMin8CharacterError"), 
        "warning"
      );
      return;
    }
    if (this.password != this.confirmPassword) {
      toastService.showToast(
        this.$t("passwordReset.passwordConfirmMismatchError"),
        "warning"
      );
      return;
    }
    try {
      this.passwordResetInProgress = true;
      await userService.changePassword(
        this.userId,
        this.hash,
        this.password,
        this.confirmPassword
      );
      toastService.showToast(
        this.$t("passwordReset.changeSuccessMsg"),
        "success"
      );
      this.$router.push("/login");
    } catch (error) {
      if (error.response) {
        this.errorMessage = this.$t("passwordReset.passwordChangeError");
      }
    }
    this.passwordResetInProgress = false;
  }

  public navigateToLogin(): void {
    this.$router.push("/login");
  }
}
</script>

<style lang="scss" scoped>
div.password-reset-page {
  div.main-section {
    padding: 1rem;
    div.logo-wrapper {
      padding: 1rem;
      img.logo {
        width: 200px;
        height: auto;
      }
    }
    div.main-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 20px;

      .password-reset-form {
        min-width: 300px;
        max-width: 350px;
        width: 100%;
        .form-field-vertical {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
        }
        .mdc-form-field,
        .mdc-text-field {
          width: 100%;
        }
        .mdc-form-field,
        .mdc-text-field {
          width: 100%;
        }
        .login-button {
          margin-top: 10px;
        }
      }

      button {
        min-width: 200px;
        max-width: 350px;
        width: 100%;
      }
    }
  }
}

@media (min-width: 641px) {
  div.home {
    padding: 30vh;
  }
}
</style>
