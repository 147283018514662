import Constants from '../core/Constants';

export default function authHeader() {
    let token = localStorage.getItem(Constants.USER_TOKEN_STORAGE_KEY);

    if (token && token !== "") { 
        return { 
            headers: {
                Authorization: 'Bearer ' + token 
            }
        };
    } else {
        return {};
    }
}