<template>
  <div class="accept-cookies-wrapper">
    <div class="accept-cookies-popup">
      <div class="title">Süti használat</div>
      <div class="description">
        Az oldal sütiket használ! A megfelelő működés érdekében, kérjük fogadd el a használatukat.
      </div>
      <ui-button raised @click="acceptCookies()">Elfogadom</ui-button>
    </div>
  </div>
</template>




<script lang="ts">
import { Vue } from "vue-class-component";
import Constants from "@/core/Constants";

export default class CookiesPopup extends Vue {
  public acceptCookies(): void
  {
    localStorage.setItem( Constants.ACCEPT_COOKIES_STORAGE_KEY, `Accepted ` + new Date() );
    this.$emit( 'cookiesAccepted' );
  }
}
</script>



<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
div.accept-cookies-wrapper {
  display: flex;
  justify-content: center;
  position: fixed;
  width: 100%;
  bottom: 10px;
  div.accept-cookies-popup {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 10px;
    background: #fed001;
    color: #36305e;
    // width: 350px;
    margin: 0 10px;
    padding: 15px;
    border-radius: 0.5rem;

    div.title {
      font-size: 1.3rem;
    }

    div.description {
      font-size: 1.1rem;
      text-align: center;
    }

    button {
      background: #ca2368;
      color: white;
      min-width: 250px;
      margin-top: 20px;
    }
  }
}
</style>
