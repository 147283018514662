
import { Options, Vue } from "vue-class-component";
import Constants from "../core/Constants";
import authService from "../services/auth.service";
import userService from "../services/user.service";

@Options({})
export default class MainHeader extends Vue
{
	public userLoggedIn = userService.userLoggedIn;
	public statementAccepted =
	userService.localUserDetails?.statementAccepted || false;
	public userName = userService.localUserDetails?.name || "";

	public navigateTo( path )
	{
		this.$router.push( path );
	}

	public logout(): void
	{
		authService.logout();
		window.location.href = '/home';
	}

	public setAppLanguage(language: string): void
	{
		this.$i18n.locale = language;
		localStorage.setItem(Constants.LANGUAGE_STORAGE_KEY, language);
	}
}
