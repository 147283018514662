<template>
	<div class="main-header no-print">
		<ui-drawer type="modal" nav-id="main-sidemenu">
			<ui-drawer-header>
				<ui-drawer-title>Menu</ui-drawer-title>
				<ui-drawer-subtitle v-if="userLoggedIn">{{ $t("user") }}: {{userName}}</ui-drawer-subtitle>
			</ui-drawer-header>
			<ui-drawer-content>
				<ui-nav>
					<!-- IF NOT LOGGED IN -->
					<ui-nav-item href="javascript:void(0)" @click="navigateTo('/login')" :class="{ active: $route.path == '/login' }" v-if="!userLoggedIn">
						{{ $t("login") }}
					</ui-nav-item>
					<ui-nav-item href="javascript:void(0)" @click="$router.push('/create-account')" :class="{ active: $route.path == '/create-account' }" v-if="!userLoggedIn">
						{{ $t("createAccount.createAccount") }}
					</ui-nav-item>
					<!-- USER LOGGED IN -->
					<ui-nav-item href="javascript:void(0)" @click="navigateTo('/register')" :class="{ active: $route.path == '/register' }" v-if="userLoggedIn">
						{{$t("eventRegistration.statement")}}
					</ui-nav-item>
					<ui-nav-item href="javascript:void(0)" @click="navigateTo('/programs')" :class="{ active: $route.path == '/programs' }" v-if="userLoggedIn && statementAccepted">
						{{$t("programs.eventPrograms")}}
					</ui-nav-item>
					<ui-nav-item href="javascript:void(0)" @click="navigateTo('/event-pass')" :class="{ active: $route.path == '/event-pass' }" v-if="userLoggedIn && statementAccepted">
						{{$t("eventPass.eventPass")}}
					</ui-nav-item>
					<ui-nav-item href="javascript:void(0)" @click="navigateTo('/faq')" :class="{ active: $route.path == '/faq' }" v-if="userLoggedIn && statementAccepted">
						{{$t("faq.menu")}}
					</ui-nav-item>
					<ui-nav-item href="javascript:void(0)" @click="logout()" v-if="userLoggedIn">
						{{ $t("logout") }}
					</ui-nav-item>
				</ui-nav>
			</ui-drawer-content>
		</ui-drawer>
		<ui-top-app-bar content-selector=".page-content" nav-id="main-sidemenu">
			<template #toolbar="{}">
				<div class="languages">
					<ui-icon-button class="language-button" :class="{ 'active-language': $i18n.locale == 'hu' }" @click="setAppLanguage('hu')">
						<span>HU</span>
					</ui-icon-button>
					<ui-icon-button class="language-button" :class="{ 'active-language': $i18n.locale == 'ro' }" @click="setAppLanguage('ro')">
						<span>RO</span>
					</ui-icon-button>
				</div>
			</template>
		</ui-top-app-bar>
	</div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import Constants from "../core/Constants";
import authService from "../services/auth.service";
import userService from "../services/user.service";

@Options({})
export default class MainHeader extends Vue
{
	public userLoggedIn = userService.userLoggedIn;
	public statementAccepted =
	userService.localUserDetails?.statementAccepted || false;
	public userName = userService.localUserDetails?.name || "";

	public navigateTo( path )
	{
		this.$router.push( path );
	}

	public logout(): void
	{
		authService.logout();
		window.location.href = '/home';
	}

	public setAppLanguage(language: string): void
	{
		this.$i18n.locale = language;
		localStorage.setItem(Constants.LANGUAGE_STORAGE_KEY, language);
	}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
div.main-header {
  // height: 45px;
  // padding: 5px;
  // display: flex;
  // flex-direction: row;
  // align-items: center;
  // justify-content: space-between;
  div.languages {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-direction: row;
  }

  button.language-button {
    font-size: 1rem;
    color: black;
    margin: 0 2px;

    span {
      display: flex;
      margin-top: -5px;
    }

    &.active-language {
      color: white;
      background: #36305e;
      border-radius: 50%;
    }
  }
}
</style>
<style lang="scss">
div.main-header {
  height: 0;
  .mdc-drawer {
    position: fixed;
    top: 0;
  }
  header.mdc-top-app-bar {
    background: $baseYellow;
    color: black;
    button#main-sidemenu {
      color: black;
    }
  }
  a.active {
    color: #6200ee;
    background: #ede1fd;
  }
}
</style>
