import axios from 'axios';
import Constants from '../core/Constants';


class AuthService {
    login(user) {
        return axios
            .post(Constants.API_URL + 'api/login.json', {
                email: user.email,
                password: user.password
            });
    }

    logout() {
        localStorage.removeItem(Constants.USER_TOKEN_STORAGE_KEY);
        localStorage.removeItem(Constants.USER_DATA_STORAGE_KEY);
    }

    register(user) {
        return axios.post(Constants.API_URL + 'api/register.json', {
            email: user.email,
            password: user.password,
            confirmPassword: user.confirmPassword,
			language: user.language
        });
    }
}

export default new AuthService();
