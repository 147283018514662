
import Constants from "@/core/Constants";
import authService from "@/services/auth.service";
import userService from "@/services/user.service";
import { Options, Vue } from "vue-class-component";

@Options({
  components: {},
})
export default class Login extends Vue {
  public loginInProgress = false;
  public errorMessage = "";
  public email = null;
  public password = null;

  public async login(): Promise<void> {
    this.loginInProgress = true;
    this.errorMessage = "";
    try {
      const login = await authService.login({
        email: this.email,
        password: this.password,
      });
      if (login?.data?.token) {
        this.writeDataToLocalStorage(
          Constants.USER_TOKEN_STORAGE_KEY,
          login.data.token
        );

        const user = await userService.getUserDetails();
        if (user?.data) {
          this.writeDataToLocalStorage(
            Constants.USER_DATA_STORAGE_KEY,
            JSON.stringify(user.data)
          );
          this.redirectUserAfterLogin(user);
        }
      }
    } catch (error) {
      console.warn(error);
      this.errorMessage = this.$t("errorMsgs.loginDataIncorrect");
      this.loginInProgress = false;
    }
  }

  public navigateToPassordReset(): void {
    this.$router.push("forgot-password");
  }

  public navigateToCreateAccount(): void {
    this.$router.push("create-account");
  }

  private redirectUserAfterLogin(user): void {
    if (user.data.type === "ADMIN") {
      window.location.href = "/admin-event-locations";
    } else {
      if (userService.localUserDetails?.statementAccepted) {
        window.location.href = "/programs";
      } else {
        window.location.href = "/register";
      }
    }
  }

  private writeDataToLocalStorage(key: string, data: string): void {
    localStorage.setItem(key, data);
  }
}
