
import { Options, Vue } from "vue-class-component";

@Options({})
export default class Home extends Vue {
  public navigateToLogin(): void {
    this.$router.push("/login");
  }
  public navigateToRegister(): void {
    this.$router.push("/create-account");
  }
}
