// import { useToast } from 'balm-ui';
import { createToast } from 'mosha-vue-toastify';
import 'mosha-vue-toastify/dist/style.css'

class ToastService {
    options = {
        position: 'bottom-center',
        timeout: 10000, 
        showCloseButton: true,
        swipeClose: true,
    };
    showToast(message, type = 'info') { 
        this.options.type = type;
        createToast(message, this.options); 
    }
}

export default new ToastService();