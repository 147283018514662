<template>
  <div class="admin-sidenav no-print">
    <ui-drawer type="modal" nav-id="admin-sidemenu">
      <ui-drawer-header>
        <ui-drawer-title>Menu</ui-drawer-title>
        <ui-drawer-subtitle>{{ $t("user") }}: {{userName}}</ui-drawer-subtitle>
      </ui-drawer-header>
      <ui-drawer-content>
        <ui-nav>
          <ui-nav-item
            href="javascript:void(0)"
            @click="
              $router.push('/admin-event-locations');
              open = false;
            "
            :class="{ active: $route.path == '/admin-event-locations' }"
            >{{ $t("eventLocation.eventLocations") }}</ui-nav-item
          >
          <ui-nav-item
            href="javascript:void(0)"
            @click="$router.push('/admin-categories')"
            :class="{ active: $route.path == '/admin-categories' }"
            >Kategoriák</ui-nav-item
          >
          <ui-nav-item
            href="javascript:void(0)"
            @click="$router.push('/admin-programs')"
            :class="{ active: $route.path == '/admin-programs' }"
            >Programok</ui-nav-item
          >
          <ui-nav-item
            href="javascript:void(0)"
            @click="$router.push('/programs')"
            :class="{ active: $route.path == '/programs' }"
            >Program preview</ui-nav-item
          >
          <ui-nav-item
            href="javascript:void(0)"
            @click="$router.push('/admin-days')"
            :class="{ active: $route.path == '/admin-days' }"
            >Napok</ui-nav-item
          >
          <ui-nav-item
            href="javascript:void(0)"
            @click="$router.push('/admin-users')"
            :class="{ active: $route.path == '/admin-users' }"
            >Felhasználók</ui-nav-item
          >
          <ui-nav-item
            href="javascript:void(0)"
            @click="$router.push('/admin-offline-guests')"
            :class="{ active: $route.path == '/admin-offline-guests' }"
            >Offline regisztráció</ui-nav-item
          >
          <ui-nav-item
            href="javascript:void(0)"
            @click="$router.push('/admin-event-checkin')"
            :class="{ active: $route.path == '/admin-event-checkin' }"
            >Event Check-in</ui-nav-item
          >
					<ui-nav-item href="javascript:void(0)" @click="$router.push('/statistics/globals')" :class="{ active: $route.path == '/statistics/globals' }">
						Statisztika: Globális
					</ui-nav-item>
					<ui-nav-item href="javascript:void(0)" @click="$router.push('/statistics/top-registrants')" :class="{ active: $route.path == '/statistics/top-registrants' }">
						Statisztika: Top Reg.
					</ui-nav-item>
					<ui-nav-item href="javascript:void(0)" @click="$router.push('/statistics/registrations')" :class="{ active: $route.path == '/statistics/registrations' }">
						Statisztika: Reg. Sor
					</ui-nav-item>
					<ui-nav-item href="javascript:void(0)" @click="logout()">{{$t("logout")}}</ui-nav-item>
        </ui-nav>
      </ui-drawer-content>
    </ui-drawer>
    <ui-top-app-bar content-selector=".page-content" nav-id="admin-sidemenu">
      Admin
    </ui-top-app-bar>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import Constants from "@/core/Constants";
import authService from "@/services/auth.service";
import userService from "@/services/user.service";

Options({});
export default class AdminHeader extends Vue {
  public userName = userService.localUserDetails?.name || "";
  public logout(): void {
    authService.logout();
    window.location.href = '/home';
  }
  public setAppLanguage(language: string): void {
    this.$i18n.locale = language;
    localStorage.setItem(Constants.LANGUAGE_STORAGE_KEY, language);
  }
}
</script>

<style lang="scss" scoped>
div.languages {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  margin-top: 2rem;
  button.language-button {
    font-size: 1rem;
    color: black;
    margin: 0 2px;

    span {
      display: flex;
      margin-top: -5px;
    }

    &.active-language {
      color: white;
      background: #36305e;
      border-radius: 50%;
    }
  }
}
</style>
<style lang="scss">
div.admin-sidenav {
  height: 0;
  .mdc-drawer {
    position: fixed;
    top: 0;
  }
  .mdc-top-app-bar {
    background: $baseYellow;
    color: black;
    button#admin-sidemenu {
      color: black;
    }
  }
  a.active {
    color: #6200ee;
    background: #ede1fd;
  }
}
</style>
