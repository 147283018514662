<template>
  <div>
    <MainHeader v-if="!showAdminHeader"></MainHeader>
    <AdminHeader v-if="showAdminHeader"></AdminHeader>
  </div>
  <div class="page-content">
    <router-view :key="$route.fullPath" />
    <CookiesPopup
      v-if="!cookiesAccepted"
      @cookiesAccepted="cookiesAcceptedEvent()"
    ></CookiesPopup>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import MainHeader from "@/components/MainHeader.vue";
import AdminHeader from "@/components/AdminHeader.vue";
import CookiesPopup from "@/components/CookiesPopup.vue";
import Constants from "@/core/Constants";
import userService from "./services/user.service";
import axios from "axios";
import authHeader from "@/services/auth-headers";

@Options({
  components: {
    MainHeader,
    AdminHeader,
    CookiesPopup,
  },
})
export default class App extends Vue {
  public cookiesAccepted = true;
  public get currentUser(): { [key: string]: string | number | boolean } {
    return userService.localUserDetails;
  }
  public get showAdminHeader(): boolean {
    if (this.currentUser?.type) {
      return this.currentUser?.type === "ADMIN";
    }

    return false;
  }

  beforeCreate(): void {
    if (localStorage.getItem(Constants.LANGUAGE_STORAGE_KEY)) {
      this.$i18n.locale = localStorage.getItem(
        Constants.LANGUAGE_STORAGE_KEY
      ) as string;
    }
  }

  async mounted(): Promise<void> {
    if (!localStorage.getItem(Constants.ACCEPT_COOKIES_STORAGE_KEY)) {
      this.cookiesAccepted = false;
    }

    const version = await this.getVersion();
    const currentHash = version?.hash;
    localStorage.setItem(Constants.CURRENT_VERSION_HASH, currentHash);
    this.initVersionCheck();
  }

  public cookiesAcceptedEvent(): void {
    this.cookiesAccepted = true;
  }

  public initVersionCheck(frequency = 1000 * 60) {
    setInterval(() => {
      this.checkVersion();
    }, frequency);
  }

  public async checkVersion() {
    try {
      const userVersionHash = localStorage.getItem(
        Constants.CURRENT_VERSION_HASH
      );
      const version = await this.getVersion();
      const currentHash = version?.hash;
      if (currentHash && userVersionHash !== currentHash) {
        localStorage.setItem(Constants.CURRENT_VERSION_HASH, currentHash);
        window.location.reload();
      }
    } catch (error) {
      console.warn(error.response.data.message);
    }
  }

  public async getVersion() {
    try {
      const fileResponse = await axios.get(
        window.location.origin + `/version.json`,
        authHeader()
      );
      return fileResponse.data;
    } catch (error) {
      if (!error.response) {
        console.warn("Error: Network Error");
      } else {
        console.warn(error.response.data.message);
      }
      return null;
    }
  }
}
</script>

<style lang="scss">
@import "@/assets/css/main.scss";

div.page-content {
  min-height: $pageMinHeight;
  // background: $basePurple;
}

@media (min-width: 320px) {
  /* smartphones, iPhone, portrait 480x320 phones */
}
@media (min-width: 481px) {
  /* portrait e-readers (Nook/Kindle), smaller tablets @ 600 or @ 640 wide. */
}
@media (min-width: 641px) {
  /* portrait tablets, portrait iPad, landscape e-readers, landscape 800x480 or 854x480 phones */
}
@media (min-width: 961px) {
  /* tablet, landscape iPad, lo-res laptops ands desktops */
}
@media (min-width: 1025px) {
  /* big landscape tablets, laptops, and desktops */
}
@media (min-width: 1281px) {
  /* hi-res laptops and desktops */
}
</style>
