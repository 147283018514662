<template>
  <div class="forgot-password-page centered-v-page account-page">
    <div class="main-section">
      <div class="logo-wrapper flex-h-center">
        <img src="../assets/img/logo.png" class="logo" @click="$router.push('/home')" />
      </div>
      <div class="page-title flex-h-center">{{$t('forgotPassword.forgotYourPasswordQ')}}</div>
      <div class="page-description flex-h-center">
        <span>{{$t("forgotPassword.noProblem")}}</span>
      </div>
      <div class="main-content">
        <ui-form type="1" item-margin-bottom="20" class="password-reset-form">
          <ui-form-field>
            <ui-textfield v-model="email" outlined :attrs="{ name: 'email' }">
              {{$t("email")}}
            </ui-textfield>
          </ui-form-field>
          <ui-button
            raised
            @click="sendEmail()"
            :disabled="!email || emailSendInProgress"
          >
            <span v-if="!emailSendInProgress">{{$t("sendEmail")}}</span>
            <ui-spinner active v-if="emailSendInProgress"></ui-spinner>
          </ui-button>
          <ui-button class="login-button" @click="navigateToLogin()"
            >{{$t("changedMindLogin")}}</ui-button
          >
        </ui-form>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import userService from "../services/user.service";
import toastService from "../services/toast.service";

@Options({})
export default class ForgotPassword extends Vue {
  public email = null;
  public emailSendInProgress = false;

  public async sendEmail(): Promise<void> {
    try {
      this.emailSendInProgress = true;
      await userService.resetPassword(this.email);
      toastService.showToast(
        this.$t("successMsgs.passwordResetEmailSent"),
        "success"
      );
      this.$router.push("login");
    } catch (error) {
      toastService.showToast(
        this.$t("errorMsgs.somethingWentWrongWhenSendingEmail"),
        "danger" 
      );
    }
    this.emailSendInProgress = false;
  }

  public navigateToLogin(): void {
    this.$router.push("login");
  }
}
</script>

<style lang="scss" scoped>
div.forgot-password-page {
  div.main-section {
    padding: 1rem;
    div.logo-wrapper {
      padding: 1rem;
      img.logo {
        width: 200px;
        height: auto;
      }
    }
    div.main-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 20px;

      .password-reset-form {
        min-width: 300px;
        max-width: 350px;
        width: 100%;
        .mdc-form-field,
        .mdc-text-field {
          width: 100%;
        }
        .login-button {
          margin-top: 10px;
        }
      }

      button {
        min-width: 200px;
        max-width: 350px;
        width: 100%;
      }
    }
  }
}

@media (min-width: 641px) {
  div.home {
    padding: 30vh;
  }
}
</style>
