
import { Options, Vue } from "vue-class-component";
import userService from "../services/user.service";
import toastService from "../services/toast.service";

@Options({
  components: {},
})
export default class VerifyAccount extends Vue {
  async mounted(): Promise<void> {
    const routeParams = this.$route?.params;
    if (routeParams?.userId && routeParams?.hash) {
      try {
        await userService.verifyUserEmail(routeParams.userId, routeParams.hash);
        toastService.showToast(
          "A felhasznalo aktivalasa sikeres. Kerjuk jelenkez be.",
          "success"
        );
      } catch (error) {
        toastService.showToast(
          "A felhasznalo aktivalasa nem sikerult.",
          "danger"
        );
      }
    }
    this.$router.push("/login");
  }
}
