
import authService from "../services/auth.service";
import toastService from "../services/toast.service";
import { Options, Vue } from "vue-class-component";

@Options({
  components: {},
})
export default class AccountCreate extends Vue {
  public email = null;
  public password = null;
  public confirmPassword = null;
  public accountCreationInProgress = false;
  public errorMessage = "";
  public gdpr = false;

  public get formInvalid(): boolean {
    return (
      !this.email ||
      this.email === "" ||
      !this.password ||
      this.password === "" ||
      !this.confirmPassword ||
      this.confirmPassword === "" ||
      !this.gdpr
    );
  }

  public createAccount(): void {
    this.accountCreationInProgress = true;
		if (this.formInvalid)
		{
			this.errorMessage = "A regisztrációs űrlap hiányos!";
			this.accountCreationInProgress = false;
			return;
		}
    this.errorMessage = "";
    authService
      .register({
        email: this.email,
        password: this.password,
        confirmPassword: this.confirmPassword,
		language: this.$i18n.locale
      })
      .then((success) => {
        if (success?.data?.success) {
          toastService.showToast(
            "A fiók létrehozása sikeres. Kérjük aktiválja fiókját az email-re küldött link segitségével."
          );
          this.$router.push("/login");
        }
      })
      .catch((error) => {
        if (error.response) {
          this.errorMessage = "Hiba történt a fiók létrehozása közben!<br>";
          const errorObj = error.response?.data?.error;
          for (const errorKey in errorObj) {
            for (const errorMsg of errorObj[errorKey]) {
              this.errorMessage += `&bull; <b>${errorMsg}</b><br>`;
            }
          }
        }
      })
      .then(() => {
        this.accountCreationInProgress = false;
      });
  }

  public navigateToLogin(): void {
    this.$router.push("login");
  }
}
