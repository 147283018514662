
import { Options, Vue } from "vue-class-component";
import Constants from "@/core/Constants";
import authService from "@/services/auth.service";
import userService from "@/services/user.service";

Options({});
export default class AdminHeader extends Vue {
  public userName = userService.localUserDetails?.name || "";
  public logout(): void {
    authService.logout();
    window.location.href = '/home';
  }
  public setAppLanguage(language: string): void {
    this.$i18n.locale = language;
    localStorage.setItem(Constants.LANGUAGE_STORAGE_KEY, language);
  }
}
