import axios from 'axios';
import authHeader from './auth-headers';
import Constants from '@/core/Constants';
import authService from './auth.service';
import moment from 'moment';


class UserService {
    userLoggedIn = !!(this.localUserDetails);

    get localUserDetails() {
        const user = localStorage.getItem(Constants.USER_DATA_STORAGE_KEY);
        return user ? JSON.parse(user) : null;
    }

    getUserDetails() {
        return axios.get(Constants.API_URL + 'api/currentuser.json', authHeader());
    }

    verifyUserEmail(userId, hash) {
        const body = {
            id: userId,
            verificationCode: hash
        };
        return axios.post(Constants.API_URL + 'api/validateemail.json', body);
    }

    resetPassword(email) {
        const body = {
            email: email
        };
        return axios.post(Constants.API_URL + 'api/resetpassword.json', body);
    }

    changePassword(userId, hash, password, confirmPassword) {
        const body = {
            id: userId,
            passwordResetCode: hash,
            password: password,
            confirmPassword: confirmPassword
        };
        return axios.post(Constants.API_URL + 'api/changepassword.json', body);
    }

    callCheckIsAdmin() {
        return axios.get(Constants.API_URL + 'api/isadmin.json', authHeader());
    }

    invalidateUser() {
        authService.logout();
    }

    updateUserStatement(name, vaccinated, recovered, tested, childrensNo, childrensName) {
        const statementUpdate = {
            statementAccepted: moment().format("YYYY-MM-DD HH:mm:ss"),
            name: name,
            vaccinated: vaccinated,
            recovered: recovered,
            tested: tested,
            children: childrensNo || 0,
            child1: null,
            child2: null,
            child3: null,
            child4: null,
            child5: null,
            child6: null
        };
        if (childrensName && childrensName.length > 0) {
            childrensName.forEach((childName, i) => {
                statementUpdate[`child${i + 1}`] = childName;
            });
        }

        return axios.post(Constants.API_URL + 'api/user.json', statementUpdate, authHeader());
    }

    getUserEvents(userId = null) {
        if (userId) {
            const config = authHeader();
            config['params'] = { user: userId };
            return axios.get(Constants.API_URL + 'api/userevents.json', config);
        }
        else {
            return axios.get(Constants.API_URL + 'api/userevents.json', authHeader());
        }
    }

    registerUserEvent(eventId, userId = null, children, language) {
        const user = this.localUserDetails;
        const data = { user: userId || user.id, event: eventId, children: children, language: language };
        return axios.post(Constants.API_URL + 'api/registerevent.json', data, authHeader());
    }

    deregisterUserEvent(eventId, userId = null) {
        const user = this.localUserDetails;
        const data = { user: userId || user.id, event: eventId };
        return axios.post(Constants.API_URL + 'api/deregisterevent.json', data, authHeader());
    }
}

export default new UserService();
