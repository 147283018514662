
import { Vue } from "vue-class-component";
import Constants from "@/core/Constants";

export default class CookiesPopup extends Vue {
  public acceptCookies(): void
  {
    localStorage.setItem( Constants.ACCEPT_COOKIES_STORAGE_KEY, `Accepted ` + new Date() );
    this.$emit( 'cookiesAccepted' );
  }
}
