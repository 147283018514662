export default {
    //API_URL: "http://86.125.189.193:8080/",
    // API_URL: "http://api.bright-works.eu/",
    API_URL: "https://api.forgatag.ro/",
    USER_TOKEN_STORAGE_KEY: "forgatag-ui:token",
    USER_DATA_STORAGE_KEY: "forgatag-ui:userdata",
    ACCEPT_COOKIES_STORAGE_KEY: "forgatag-ui:acceptCookies",
    LANGUAGE_STORAGE_KEY: "forgatag-ui:language",
    LATEST_SEARCHED_BACKUPCODES_KEY: "forgatag-ui:latestSearchedBackupCodes",
    CURRENT_VERSION_HASH: "forgatag-ui:versionHash"
}
