<template>
  <div class="login-page centered-v-page account-page">
    <div class="main-section">
      <div class="logo-wrapper flex-h-center">
        <img
          src="../assets/img/logo.png"
          class="logo"
          @click="$router.push('/home')"
        />
      </div>
      <div class="page-title flex-h-center">{{ $t("login") }}</div>
      <div class="main-content">
        <ui-form type="1" item-margin-bottom="20" class="login-form">
          <ui-form-field>
            <ui-textfield
              outlined
              :attrs="{ name: 'email' }"
              v-model="email"
              required
              @keyup.enter="login()"
            >
              {{ $t("email") }}
            </ui-textfield>
          </ui-form-field>
          <ui-form-field>
            <ui-textfield
              outlined
              :input-type="'password'"
              :attrs="{ name: 'password' }"
              v-model="password"
              required
              @keyup.enter="login()"
            >
              {{ $t("password") }}
            </ui-textfield>
          </ui-form-field>
          <div class="forgot-password-wrapper">
            <a
              class="forgot-password-router"
              href="javascript:void(0)"
              @click="navigateToPassordReset()"
              >{{ $t("forgotPassword.forgotPassword") }}</a
            >
          </div>
          <ui-alert state="error" v-if="errorMessage !== ''">{{
            errorMessage
          }}</ui-alert>
          <ui-button raised @click="login()" :disabled="loginInProgress">
            <span v-if="!loginInProgress">{{ $t("loginButton") }}</span>
            <ui-spinner active v-if="loginInProgress"></ui-spinner>
          </ui-button>
          <ui-button
            class="register-button"
            @click="navigateToCreateAccount()"
            >{{ $t("noAccountRegister") }}</ui-button
          >
        </ui-form>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import Constants from "@/core/Constants";
import authService from "@/services/auth.service";
import userService from "@/services/user.service";
import { Options, Vue } from "vue-class-component";

@Options({
  components: {},
})
export default class Login extends Vue {
  public loginInProgress = false;
  public errorMessage = "";
  public email = null;
  public password = null;

  public async login(): Promise<void> {
    this.loginInProgress = true;
    this.errorMessage = "";
    try {
      const login = await authService.login({
        email: this.email,
        password: this.password,
      });
      if (login?.data?.token) {
        this.writeDataToLocalStorage(
          Constants.USER_TOKEN_STORAGE_KEY,
          login.data.token
        );

        const user = await userService.getUserDetails();
        if (user?.data) {
          this.writeDataToLocalStorage(
            Constants.USER_DATA_STORAGE_KEY,
            JSON.stringify(user.data)
          );
          this.redirectUserAfterLogin(user);
        }
      }
    } catch (error) {
      console.warn(error);
      this.errorMessage = this.$t("errorMsgs.loginDataIncorrect");
      this.loginInProgress = false;
    }
  }

  public navigateToPassordReset(): void {
    this.$router.push("forgot-password");
  }

  public navigateToCreateAccount(): void {
    this.$router.push("create-account");
  }

  private redirectUserAfterLogin(user): void {
    if (user.data.type === "ADMIN") {
      window.location.href = "/admin-event-locations";
    } else {
      if (userService.localUserDetails?.statementAccepted) {
        window.location.href = "/programs";
      } else {
        window.location.href = "/register";
      }
    }
  }

  private writeDataToLocalStorage(key: string, data: string): void {
    localStorage.setItem(key, data);
  }
}
</script>

<style lang="scss" scoped>
div.login-page {
  div.main-section {
    padding: 1rem;
    div.logo-wrapper {
      padding: 1rem;
      img.logo {
        width: 200px;
        height: auto;
      }
    }
    div.main-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 20px;

      .login-form {
        min-width: 300px;
        max-width: 350px;
        width: 100%;
        .mdc-form-field,
        .mdc-text-field {
          width: 100%;
        }
        div.forgot-password-wrapper {
          padding: 0 0 2.5rem 0;
          a.forgot-password-router {
            color: $basePurple;
            font-weight: 600;
            cursor: pointer;
          }
        }
        .register-button {
          margin-top: 10px;
        }
      }

      button {
        min-width: 200px;
        max-width: 350px;
        width: 100%;
      }
    }
  }
}

@media (min-width: 641px) {
  div.home {
    padding: 30vh;
  }
}
</style>
